import { Button } from '@components'
import { Typography } from '@mui/material'
import { Dns, Error, VpnKey, AccessAlarms } from '@mui/icons-material'
import { getUpgradeUrl } from '@utils/license'
import { Link } from 'gatsby-material-ui-components'
import React from 'react'
import { GrUpgrade } from 'react-icons/gr'

export const licenseExpiringNotice = {
  icon: <AccessAlarms color="primary" fontSize="large" />,
  message: (
    <Typography variant="body1">
      Your license is about to expire. Visit{' '}
      <Link target="_blank" href="https://www.sandflysecurity.com">
        www.sandflysecurity.com
      </Link>{' '}
      to renew today. If you have your new license key,{' '}
      <Link to="/settings/license/">enter it here</Link>.
    </Typography>
  ),
  action: (
    <Button
      variant="contained"
      color="primary"
      size="large"
      style={{ whiteSpace: 'nowrap' }}
      onClick={() =>
        window.open(
          `https://www.sandflysecurity.com/pricing/`,
          '_blank',
          'noopener'
        )
      }
    >
      Renew Here
    </Button>
  )
}

export const licenseExpiredNotice = {
  icon: <Error color="error" fontSize="large" />,
  message: (
    <Typography variant="body1">
      {`Your license has expired and you will not be able scan your hosts. Upgrade your license via our website sandflysecurity.com.`}
    </Typography>
  ),
  action: (
    <Button
      variant="contained"
      color="primary"
      size="large"
      style={{ whiteSpace: 'nowrap' }}
      onClick={() =>
        window.open(
          `https://www.sandflysecurity.com/pricing/`,
          '_blank',
          'noopener'
        )
      }
    >
      Renew License
    </Button>
  )
}

export const unlicensedNotice = {
  icon: <VpnKey color="primary" fontSize="large" />,
  message: (
    <Typography variant="body1">
      Thank you for installing Sandfly. If you need a license, go to{' '}
      <Link target="_blank" href="https://www.sandflysecurity.com">
        www.sandflysecurity.com
      </Link>{' '}
      for a free demo or to purchase. If you have your license key,{' '}
      <Link to="/settings/license/">enter it here</Link>.
    </Typography>
  ),
  action: (
    <Button
      variant="contained"
      color="primary"
      size="large"
      style={{ whiteSpace: 'nowrap' }}
      to="/settings/license/"
    >
      Enter License Here
    </Button>
  )
}

export const licenseExceededNotice = {
  icon: <Error color="error" fontSize="large" />,
  message: (
    <Typography variant="body1">
      {`You've exceeded your license limit and will not be able scan your hosts. Upgrade your license via our website sandflysecurity.com.`}
    </Typography>
  ),
  action: (
    <Button
      variant="contained"
      color="primary"
      size="large"
      style={{ whiteSpace: 'nowrap' }}
      onClick={() =>
        window.open(
          `https://www.sandflysecurity.com/pricing/`,
          '_blank',
          'noopener'
        )
      }
    >
      Upgrade
    </Button>
  )
}

export const upgradeNotice = {
  icon: <Dns color="secondary" fontSize="large" />,
  message: (
    <Typography variant="body1">
      {`Thank you for using Sandfly. Upgrade your license for more features at sandflysecurity.com.`}{' '}
    </Typography>
  ),
  action: (
    <Button
      variant="contained"
      color="secondary"
      size="large"
      startIcon={<GrUpgrade className="stroke-reset" />}
      style={{ whiteSpace: 'nowrap' }}
      onClick={() =>
        window.open(
          getUpgradeUrl({ componentName: 'Upgrade Notice Banner' }),
          '_blank',
          'noopener'
        )
      }
    >
      Visit Website
    </Button>
  )
}
