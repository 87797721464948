import React, { FC, MouseEventHandler, useEffect, useState } from 'react'
import { navigate, PageProps } from 'gatsby'
import { Button, ButtonGroup } from '@mui/material'
import {
  GridLinkOperator,
  GridRowParams,
  GridSortModel
} from '@mui/x-data-grid-pro'
import {
  deleteAllResults,
  deleteResults,
  deleteResultsById,
  getSummaryResults,
  TGetResultsFilterProps,
  TResultsSummaryResponse
} from '@utils/api'
import { filterModelDecorator, transformApiResponseToRows } from '@utils/tables'
import { Delete, Refresh, WarningRounded } from '@mui/icons-material'
import { Notice, WithCaution } from '@components'
import GenericTablePage from '@templates/Generic/Table'
import useApi from '@hooks/useApi'
import { canRequest, resultsColumns, resultsPresets } from '@utils/results'
import { UPGRADE_PAGE } from '@utils/license'
import useLicense from '@hooks/useLicense'
import { upgradeNotice } from '@components/Notice/presets'

type ResultsPageProps = PageProps<
  object,
  object,
  { requestParams?: TGetResultsFilterProps }
>

const ResultsPage: FC<ResultsPageProps> = ({ location }) => {
  const requestParamsFromState = location?.state?.requestParams || {}
  const { isTrial } = useLicense()
  const [filter, setFilterModel] = useState<TGetResultsFilterProps['filter']>()

  const setFilter = (filter: TGetResultsFilterProps['filter']) =>
    setFilterModel(filter)

  const hasSort = !!requestParamsFromState?.sort

  const [sort, setSort] = React.useState<GridSortModel>(
    hasSort
      ? (requestParamsFromState.sort as GridSortModel)
      : [
          {
            field: 'data.end_time',
            sort: 'desc'
          }
        ]
  )

  const [requestParams, setRequestParams] = useState<TGetResultsFilterProps>({
    size: 10000,
    flatten: true,
    summary: true,
    filter,
    sort,
    ...requestParamsFromState
  })

  const api = useApi<TResultsSummaryResponse>({
    apiMethod: getSummaryResults,
    params: requestParams,
    requestOnMount: false
  })

  const deleteApi = useApi<any>({
    apiMethod: deleteResults,
    requestOnMount: false
  })

  const deleteAllApi = useApi<any>({
    apiMethod: deleteAllResults,
    requestOnMount: false
  })

  const { loading, error, response, makeRequest, enqueueSnackbar } = api

  const rowClassName = ({ row }: GridRowParams): string => {
    const base = `DataGrid__row--status-${row['data.status']}`
    const isMasked = row['data.masked'] || false
    const masked = isMasked ? ` DataGrid__row--masked-${isMasked}` : ''

    return base + masked
  }

  const rowClickHandler = (requestParams: GridRowParams) => {
    if (requestParams.row['data.masked'] === true) {
      return navigate(UPGRADE_PAGE, {
        state: { feature: 'unlimited_alerts' }
      })
    }

    navigate(`/results/result/${requestParams.id}/`)
  }

  const handleDelete = () => {
    deleteApi.makeRequest(filter).then(() => {
      enqueueSnackbar('Delete in progress. This may take a moment.', {
        variant: 'success'
      })

      setTimeout(() => api.makeRequest(requestParams), 1000)
    })
  }

  const handleAllDelete = () => {
    deleteAllApi.makeRequest().then(() => {
      enqueueSnackbar('All Results Deleted.', {
        variant: 'success'
      })

      setTimeout(() => api.makeRequest(requestParams), 1000)
    })
  }

  const handleRefresh: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault()
    api.refresh(requestParams)
  }

  const selectionLogicHandler = (params: GridRowParams) => {
    return !params.row['data.masked'] === true
  }

  const rows =
    transformApiResponseToRows({
      response
    }) || []

  useEffect(() => {
    setRequestParams((oldParams) => ({
      ...oldParams,
      filter,
      sort
    }))
  }, [filter, sort])

  useEffect(() => {
    if (!loading) {
      // catch filters without values causing 400 error
      if (canRequest(requestParams.filter)) {
        makeRequest(requestParams)
        navigate(
          `${location.pathname}?${btoa(JSON.stringify(requestParams))}`,
          {
            state: { requestParams },
            replace: true
          }
        )
      }
    }
  }, [requestParams])

  useEffect(() => {
    setFilter(
      requestParamsFromState.filter
        ? filterModelDecorator(requestParamsFromState.filter)
        : {
            items: [
              {
                columnField: 'data.status',
                operatorValue: 'is',
                value: 'alert',
                id: 'default-alert-filter'
              }
            ],
            linkOperator: 'and' as GridLinkOperator
          }
    )
  }, [])

  return (
    <GenericTablePage
      title="Results"
      api={api}
      notice={isTrial ? <Notice {...upgradeNotice} /> : null}
      breadcrumbs={[
        { title: 'Results', to: '/results/' },
        { title: 'All Results' }
      ]}
      actions={
        <ButtonGroup
          color="primary"
          variant="outlined"
          aria-label="Results Actions"
        >
          <Button endIcon={<Refresh />} onClick={handleRefresh}>
            Refresh
          </Button>
          <WithCaution message="You are about to delete all results that match your filter. This action is permanent. Are you sure you wish to proceed?">
            <Button
              disabled={!filter || filter.items.length < 1}
              onClick={() => handleDelete()}
              endIcon={<Delete />}
            >
              Delete Matches
            </Button>
          </WithCaution>
          <WithCaution message="You are about to delete all results. This action is permanent. Are you sure you wish to proceed?">
            <Button
              onClick={() => handleAllDelete()}
              endIcon={<WarningRounded />}
            >
              Delete All Results
            </Button>
          </WithCaution>
        </ButtonGroup>
      }
      gridOptions={{
        loading: loading,
        error: error || null,
        columns: resultsColumns,
        rows,
        rowCount: response?.total || 0,
        disableSelectionOnClick: true,
        disableColumnMenu: true,
        checkboxSelection: true,
        filterMode: 'server',
        filterModel: filter,
        setFilter,
        isRowSelectable: selectionLogicHandler,
        // onFilterModelChange: onFilterChange,
        sortingMode: 'server',
        sortModel: sort,
        setSort,

        // onSortModelChange: handleSortModelChange,
        onRowClick: rowClickHandler,
        total: response?.total || 0,
        maxResults: requestParams.size,
        getRowClassName: rowClassName
      }}
      presets={resultsPresets}
      bulkActions={[
        {
          text: 'Delete Selected',
          icon: <Delete fontSize="small" />,
          action: async (selection: string[]) => {
            await deleteResultsById(selection).then(() => {
              enqueueSnackbar('Delete in progress. This may take a moment.', {
                variant: 'success'
              })
              setTimeout(() => makeRequest(requestParams), 1000)
            })
          },
          autoRefresh: false
        }
      ]}
    ></GenericTablePage>
  )
}

export default ResultsPage
